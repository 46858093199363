import { ClientFormType, ClientFormValues, ClientType } from 'types';
import { RtkTagTypes, baseApi } from './baseApi';

export const clientApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query<ClientType[], void>({
      query: () => ({
        url: '/clients',
        method: 'GET',
      }),
      providesTags: [RtkTagTypes.Clients],
    }),
    getClient: builder.query<ClientFormValues, string>({
      query: (id) => ({
        url: `/clients/${id}/profile`,
        method: 'GET',
      }),
      keepUnusedDataFor: 10,
      providesTags: [RtkTagTypes.Client],
    }),
    updateClient: builder.mutation<boolean, { id: string; client: FormData }>({
      query: ({ id, client }) => ({
        url: `/clients/${id}/profile`,
        method: 'PUT',
        body: client,
      }),
      invalidatesTags: [RtkTagTypes.Clients, RtkTagTypes.Client],
    }),
    addClient: builder.mutation<boolean, { client: FormData }>({
      query: ({ client }) => {
        return {
          url: '/clients',
          method: 'POST',
          body: client,
          validateStatus: (response, body) => {
            if (response.status === 500) {
              return false;
            }
            return response.ok;
          },
        };
      },
      invalidatesTags: [RtkTagTypes.Clients],
    }),
    deleteClient: builder.mutation<boolean, string>({
      query: (id) => ({
        url: `/clients/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [RtkTagTypes.Clients, RtkTagTypes.Client],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetClientQuery,
  useAddClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
} = clientApi;
