import React, { useState } from 'react';
import { ApiErrorType, BlogTopic } from 'types';
import MDEditor from '@uiw/react-md-editor';

import { Box, HStack, Heading, SimpleGrid, Spinner, useToast, Textarea } from '@chakra-ui/react';
import Button from 'components/common/Button';
import { useGetProjectSummaryQuery, useGetProjectTopicsQuery } from 'services/api/projects';

interface TopicProps {
  clientId: string;
  setSelectedTopic: React.Dispatch<React.SetStateAction<BlogTopic | undefined>>;
  handleGoBack: () => void;
  handleGoForward: () => void;
}

const Topic = ({ clientId, setSelectedTopic, handleGoBack, handleGoForward }: TopicProps) => {
  const [topic, setTopic] = useState<BlogTopic | undefined>();
  const [inputValue, setInputValue] = useState<string>('');
  const toast = useToast();

  const { data: summary, isLoading: isSummaryLoading, isError, error } = useGetProjectSummaryQuery(clientId);
  const { data: suggestedTopics, isLoading: isTopicsLoading } = useGetProjectTopicsQuery(summary, { skip: isSummaryLoading });

  const onChangeInput = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(value);

    if (value.length) {
      setTopic({
        name: value,
        uuid: null,
        id: (suggestedTopics?.length ?? 0) + 1,
      });
    } else {
      setTopic(undefined);
    }
  };

  const handleConfirmClick = () => {
    if (topic?.name && summary) {
      setSelectedTopic(topic);
      handleGoForward();
    } else {
      toast({
        title: 'No topic selected',
        description: 'Please select or enter a topic before confirming.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        
      });
    }
  };

  if (isError) {
    toast({
      title: 'Error loading summary',
      description: (error as ApiErrorType)?.data?.detail,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    handleGoBack();
  }
  return (
    <Box>
      <Heading as="h2" size="md" mb={4}>
        Client summary
      </Heading>
      {isSummaryLoading ? (
        <Box mb={4}>
          <Spinner /> Loading client summary
        </Box>
      ) : (
        <>
          <Box mb={8}>
            <MDEditor.Markdown disableCopy source={summary?.content} style={{ whiteSpace: 'pre-wrap', color: 'black', background: 'white', padding: '20px' }} />
          </Box>

          <Heading as="h2" size="md" mb={4}>
            Select a suggested blog topic
          </Heading>

          {isTopicsLoading ? (
            <Box mb={4}>
              <Spinner /> Loading suggested topics
            </Box>
          ) : (
            <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={4}>
              {suggestedTopics?.map((localTopic) => (
                <Box
                  key={localTopic.id}
                  p={4}
                  borderWidth="1px"
                  borderRadius="lg"
                  bg={topic?.id === localTopic.id ? 'blue.100' : 'white'}
                  _hover={{ cursor: 'pointer', backgroundColor: 'blue.100' }}
                  onClick={() => {
                    setTopic(localTopic);
                    setInputValue('');
                  }}
                >
                  {localTopic.name}
                </Box>
              ))}
              <Textarea
                placeholder="Type a new topic"
                value={inputValue}
                bg={inputValue ? 'blue.100' : 'white'}
                _hover={{ cursor: 'pointer', backgroundColor: 'blue.100' }}
                onChange={onChangeInput}
                height="100%"
                p={4}
                borderWidth="1px"
                borderRadius="lg"
              />
            </SimpleGrid>
          )}
        </>
      )}
      <HStack justifyContent="flex-start" mt={6}>
        <Button onClick={handleGoBack} variant="outline" colorScheme="purple">
          Go Back to Dashboard
        </Button>
        {summary && (
          <Button colorScheme="purple" onClick={handleConfirmClick}>
            Confirm
          </Button>
        )}
      </HStack>
    </Box>
  );
};

export default Topic;
