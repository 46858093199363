import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import BlogCreate from 'routes/BlogCreate';
import Dashboard from 'routes/Dashboard';
import LoginPage from 'routes/LoginPage';
import ClientHomePage from 'routes/Client/HomePage';

import { REACT_APP_CLIENT_ID } from 'utils/consts';

import './app/monitoring/dataDog';

const App: React.FC = () => {
  return (
    <GoogleOAuthProvider clientId={REACT_APP_CLIENT_ID!}>
      <BrowserRouter>
        <Routes>
          <Route path="/blog_create/:clientId" element={<BlogCreate />} />
          <Route path="/client/hp/:clientId" element={<ClientHomePage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/" element={<LoginPage />} />

          {/* General catcher, redirect to home page */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};

export default App;
