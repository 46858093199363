import { Box, Center, Flex, Heading, Link, SimpleGrid, Spinner, Tag, TagLabel, TagLeftIcon, Text } from '@chakra-ui/react';
import { store } from 'app/store';
import Avatar from 'components/common/Avatar';
import Button from 'components/common/Button';
import React, { useState } from 'react';
import { FaGoogleDrive } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { baseApi, RtkTagTypes } from 'services/api/baseApi';
import { useGetClientsQuery } from 'services/api/client';
import { useGetProjectsQuery } from 'services/api/projects';
import { getDateTime } from 'utils/dateTime';
import { useUserinfoHook } from 'utils/useUserinfoHook';

export const Projects = ({ clientId, perPage = 4 }: { clientId?: string; perPage?: number }) => {
  const { data: projects, isLoading } = useGetProjectsQuery();
  const { data: clients } = useGetClientsQuery();
  const { isActive: isUserActive } = useUserinfoHook();

  const navigate = useNavigate();
  const getClient = (clientId: string) => clients?.find((client) => client.id === clientId);

  const limitIncrement = perPage;
  const [limit, setLimit] = useState(limitIncrement);

  return (
    <Box px={8} py={4} mt={8}>
      {/* Heading Section */}
      <Flex justify="space-between" align="center" mb={4}>
        <Heading as="h2" color="purple.700" opacity="50%">
          Recent Projects
        </Heading>
        {clientId && (
          <Button
            colorScheme="teal"
            size="sm"
            onClick={() => {
              store.dispatch(baseApi.util.invalidateTags([RtkTagTypes.ProjectsSummary, RtkTagTypes.ProjectsTopics]));
              navigate(`/blog_create/${clientId}`);
            }}
            isDisabled={!isUserActive}
          >
            Create a new project
          </Button>
        )}
      </Flex>

      {/* Projects Grid */}
      <SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} spacing={4}>
        {projects
          ?.filter((project) => !clientId || (clientId && project.client_id === clientId))
          .slice(0, limit)
          .map((project) => {
            const { updated, topic_name, topic_id, gdrive_blog_url } = project;
            const client = getClient(project.client_id);
            return (
              <Flex
                key={`${topic_id}${gdrive_blog_url}`}
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                p={4}
                gap={4}
                direction="column"
                position="relative"
                boxShadow="sm"
                transition="all 0.2s ease"
                _hover={{
                  boxShadow: 'lg',
                  transform: 'translateY(-4px)',
                }}
              >
                <Heading as="h4" size="md" color="blackAlpha.800">
                  {topic_name}
                </Heading>

                <Flex direction="column" gap={4}>
                  {client && (
                    <Flex align="center">
                      <Text as="span" fontSize="sm" mr={1}>
                        By
                      </Text>
                      <Avatar logo={client.logo} name={client.name} boxSize="20px" mx={2} size="sm" />
                      <Text as="span" fontSize="sm">
                        {client.name}
                      </Text>
                    </Flex>
                  )}
                  {updated && <Text fontSize="sm">Edited: {getDateTime(updated.toString())}</Text>}
                </Flex>
                <Link href={gdrive_blog_url} isExternal>
                  <Tag colorScheme="purple" borderRadius="lg" variant="solid">
                    <TagLeftIcon as={FaGoogleDrive} />
                    <TagLabel>View in Google Drive</TagLabel>
                  </Tag>
                </Link>
              </Flex>
            );
          })}
      </SimpleGrid>

      {/* Show More Button */}
      {(projects?.length ?? 0) > limit && (
        <Center>
          <Button mt={4} onClick={() => setLimit(limit + limitIncrement)} colorScheme="teal">
            Show more
          </Button>
        </Center>
      )}

      {/* Loading Spinner */}
      {isLoading && (
        <Box mb={4}>
          <Spinner /> Loading projects ...
        </Box>
      )}
    </Box>
  );
};
