import { Toast, useDisclosure, useToast } from '@chakra-ui/react';
import { ClientForm } from 'components/common/ClientForm';
import { Modal } from 'components/common/Modal';
import React, { useEffect } from 'react';
import { useGetClientQuery, useUpdateClientMutation } from 'services/api/client';
import { ApiErrorType } from 'types';

export const EditClient = ({ clientId, onEditClose }: { clientId: string; onEditClose: () => void }) => {
  const { data: client, isLoading, isSuccess, isError, error } = useGetClientQuery(clientId);
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();
  const [saveClient, { isLoading: isSaving }] = useUpdateClientMutation();

  const toast = useToast();
  const toastId = 'edit-client-toast';

  useEffect(() => {
    isSuccess && onOpen();
  }, [isSuccess]);

  useEffect(() => {
    if (error && isError && !toast.isActive(toastId)) {
      toast({ id: toastId, title: 'Error', description: (error as ApiErrorType).data?.detail, status: 'error', duration: 2000, isClosable: true });
      onModalClose();
    }
  }, [isError, error]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const onClose = () => {
    onEditClose();
    onModalClose();
  };

  if (isError) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={`Editing Client "${client?.company}"`}>
      <ClientForm onClose={onClose} client={client} saveClient={(client) => saveClient({ id: clientId, client })} isSaving={isSaving} />
    </Modal>
  );
};
