import { Box, HStack, Heading, Link, Spinner, Text, Textarea, VStack, useClipboard, useToast } from '@chakra-ui/react';
import Button from 'components/common/Button';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetProjectContentQuery, useSaveProjectContentMutation } from 'services/api/projects';
import { BlogTopic } from 'types';

interface ApprovalProps {
  topic: BlogTopic;
  handleGoBack: () => void;
  clientId: string;
}

const Approval = ({ topic, handleGoBack, clientId }: ApprovalProps) => {
  const [blogContent, setBlogContent] = useState<string>('');

  const [googleDocLink, setGoogleDocLink] = useState<string>('');
  const { hasCopied, onCopy } = useClipboard(googleDocLink);

  const { data, error, isLoading } = useGetProjectContentQuery({ topic, clientId });

  const [saveProjectContent, { isLoading: isUpdating }] = useSaveProjectContentMutation();

  useEffect(() => {
    if (!isLoading && data) {
      setBlogContent(data.content);
      setGoogleDocLink(data.googleDocLink);
    }
  }, [isLoading, data, error]);

  const toast = useToast();
  const navigate = useNavigate();

  const handleConfirmClick = async () => {
    try {
      const response = await saveProjectContent({
        content: blogContent,
        clientId,
        topic_id: topic.id,
        topic,
      }).unwrap();
  
      if (response.url) {
        setGoogleDocLink(response.url); // Set the link in state immediately
        toast({
          title: 'Blog Saved',
          description: 'You can find the blog in your GDrive',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
  
      // Optionally, navigate or trigger another UI action
      navigate('/client/hp/' + clientId);
    } catch (error) {
      console.error("Error saving project content:", error);
      toast({
        title: 'Blog Not Saved',
        description: 'Some errors occurred while saving! Check logs for more info.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  

  return (
    <Box>
      <VStack spacing={6} align="stretch">
        <Heading as="h2" size="md" mb={4}>
          Final Blog Copy Approval
        </Heading>
        <Text fontSize="md">Review blog content before saving the project.</Text>
        {isLoading && (
          <Box mb={4}>
            <Spinner /> Loading blog content
          </Box>
        )}
        {blogContent && <Textarea backgroundColor="white" whiteSpace="pre-wrap" minHeight="300px" value={blogContent} onChange={(e) => setBlogContent(e.target.value)} />}

        {googleDocLink && (
          <HStack>
            <Link color="blue.500" href={googleDocLink} isExternal>
              OPEN GOOGLE DOC
            </Link>
            <Button onClick={onCopy} ml={2}>
              {hasCopied ? 'Copied' : 'Copy'}
            </Button>
          </HStack>
        )}
        <HStack justifyContent="flex-start">
          <Button onClick={handleGoBack} variant="outline" colorScheme="purple">
            Go Back to Outline
          </Button>
          {blogContent && (
            <Button colorScheme="purple" onClick={handleConfirmClick} isLoading={isUpdating} loadingText="Saving...">
              Confirm Blog
            </Button>
          )}
        </HStack>
      </VStack>
    </Box>
  );
};

export default Approval;
